<template>
  <div
    data-testid="products-dropdown"
    class="products"
  >
    <div
      v-click-outside="closeProducts"
      class="products__wrap"
    >
      <span
        v-if="products.length === 1"
        class="products__trigger products__trigger_single"
      >
        {{ activeProduct.name }}
      </span>

      <span
        v-else
        class="products__trigger"
        :class="{ products__trigger_active: open }"
        @click="open = !open"
      >
        {{ activeProduct.name }}
      </span>

      <ul
        v-if="open"
        class="products__list"
      >
        <li
          v-for="product in products"
          :key="product.id"
          :class="{ 'products__item_active': product.id === activeProduct.id }"
          class="products__item"
          @click="selectProduct(product)"
        >
          <div class="products__item-selection" />

          <span class="products__item-name">
            {{ product.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductsDesktop',

  props: {
    products: {
      type: Array,
      required: true,
    },

    activeProduct: {
      type: Object,
      required: true,
    },

    setActiveProduct: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  methods: {
    closeProducts() {
      this.open = false;
    },

    selectProduct(product) {
      new Promise(resolve => {
        this.setActiveProduct(product);
        resolve();
      }).then(() => {
        this.closeProducts();
        window.location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/mixins';

.products {
  display: none;
  margin: 0 32px 0 0;
  border-left: 1px solid rgba($secondary, 0.06);
  border-right: 1px solid rgba($secondary, 0.06);

  &__wrap {
    position: relative;
    height: 80px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__trigger {
    font: 600 18px/24px $font-bold;
    position: relative;
    padding-right: 20px;

    @include hover {
      cursor: pointer;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      background: url('~@/assets/icons/bottom-arrow.svg') no-repeat center center;
    }

    &_active {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &_single {
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }

  &__list {
    position: absolute;
    top: 62px;
    left: 32px;
    width: 196px;
    padding: 8px;
    margin: 0;
    border: 1px solid $secondary;
    border-radius: 12px;
    background-color: $primary;
    z-index: 1000;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    list-style: none;

    @include hover {
      cursor: pointer;
      background: $item-active-background;
    }

    &_active{
      background: $item-active-background;

      .products__item-selection {
        &::before {
          display: block;
        }
      }
    }
  }

  &__item-selection {
    position: relative;
    height: 16px;
    min-width: 16px;
    max-width: 16px;
    margin-right: 16px;
    border: 1px solid $secondary;
    border-radius: 50%;

    &::before {
      display: none;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $secondary;
    }
  }

  &__item-name {
    font: 600 15px/18px $font-bold;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .products {
    display: block;
    border-left: none;
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  .products {
    border-left: 1px solid rgba($secondary, 0.06);
  }
}
</style>
